import React, { useState, useEffect } from 'react';
import './cancelPayment.scss';
import Avatar from 'react-avatar';
import logo from '../old/assets/images/logo/logo-morphing-white.gif';
import { navigate } from 'gatsby';
import { getCheckoutSession, getArtistByPaymentSessionId } from '../old/utils/api';
import { DASHBOARD } from '../router/routes/index';

const CancelPayment = () => {
	const [session, setSession] = useState({});
	const [artistName, setArtistName] = useState('');
	const [artistPic, setArtistPic] = useState(null);
	const [sessionId, setSessionId] = useState(null);

	const getArtist = () => {
		getArtistByPaymentSessionId({ id: sessionId }).then((result) => {
			setArtistName(result.fullname);
			setArtistPic(result.picture);
		});
	};

	useEffect(() => {
		async function fetchSession() {
			getCheckoutSession('/checkout-session?sessionId=' + sessionId).then((res) => setSession(res));
		}
		if (sessionId) {
			fetchSession();
			getArtist();
		}
	}, [sessionId]);

	useEffect(() => {
		setSessionId(new URLSearchParams(document.location.search.substring(1)).get('session_id'));
	}, []);

	return (
		<div className="cancelPaymentComponent">
			<img alt="quan" className="cancelPaymentComponent_logo" src={logo} />
			<div className="cancelPaymentComponent_wrapper">
				<Avatar size="100" round={true} src={artistPic} className="cancelPaymentComponent_wrapper_avatar" />
				<h3>{artistName} is still available for you!</h3>
				<p className="t3">
					It’s okay, maybe you want to do it later. Please, ask {artistName} anything you would like to know when you
					are ready. Thank you for supporting our amazing artists!
				</p>
				<div className="button" onClick={() => navigate(DASHBOARD)}>
					<p>ASK AGAIN</p>
				</div>
			</div>
		</div>
	);
};

export default CancelPayment;
