export const HOME_SCREEN = '/';
// AUTH
export const LOGIN_SCREEN = `/login`;
export const SIGNUP_SCREEN = `/signup`;
export const FORGOT_PASS_SCREEN = `/forgot`;
export const RECOVER_PASS_SCREEN = `/changePassword`;
export const CHECK_YOUR_EMAIL_SCREEN = `/check_email`;

// Footer list Items routes

export const PRIVACY_POLICY = `/privacy-policy`;
export const TERMS_OF_USE = `/terms-of-use`;

export const ARTIST_SCREEN = `/artist`;
export const MESSENGER = `/messenger`;
export const DASHBOARD = `/dashboard`;
